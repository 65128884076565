header {
    background-color: #ff502c;
    height: 80px;
    padding: 10px;
    font: normal 14px/21px Arial;
    color: #fff;
}
.place-desc {
    font-size: 0;
    line-height: 60px;
    &__icon-cont {
        width: 60px;
        height: 60px;
        line-height: 60px;
        display: inline-block;
        vertical-align: middle;
        margin-right: -60px;
        position: relative;
        z-index: 1;
        &__icon {
            display: inline-block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__text {
        color: #fff;
        font: normal 20px/25px Arial;
        display: inline-block;
        padding-left: 70px;
        vertical-align: middle;
        &--no-logo {
            padding-left: 0;
        }
        a {
            color: #fff;
        }
    }
}
.company-info {
    float: right;
    text-align: right;
    margin: 3px 0 0;
    &__line {
        display: block;
        margin-bottom: 6px;
    }
    &__hidden-link {
        color: #fff !important;
        margin: 0 0 0 10px;
    }
    &__link {
        color: #fff !important;
        border-bottom: 1px solid #ff9680;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        margin: 0 0 0 10px;
        transition: 0.3s;
        &:hover {
            border-bottom-color: transparent;
        }
    }
}
